/**
 * some variables
 */
let debug = false

let cart = {

    translateToParams: function (params) {
        let urlParams = new URLSearchParams();

        for (let param in params) {
            urlParams.set(param, params[param]);
        }

        return urlParams;
    } ,

    addToCart: function (product, quantity, tooltipEl) {
        if (debug) {
            console.log("id: " + product + " q: " + (typeof(quantity) != 'undefined' ? quantity : 1))
        }

        return fetch('index.php?route=vima/cart/add', {
            method : 'POST' ,
            credentials: 'include' ,
            body : cart.translateToParams({
                'product_id' : product ,
                'quantity'   : (typeof(quantity) != 'undefined' ? quantity : 1)
            })
        }).then(function(result) {
            if (tooltipEl) {
                tooltipEl.classList.add('show');
                setTimeout(function () {
                    tooltipEl.classList.remove('show');
                }, 2000);
            }

            return result.json()
        }).then(function(result) {
            if (result && result.redirect) {
                window.location = result.redirect;
            } else {
                cart.loadInfo();
            }
            if (debug) { console.log(result); }
        });
    } ,

    loadInfo: function() {
        fetch('index.php?route=vima/cart/info')
            .then(function (response) {
                return response.json()
            }).then(function (data) {

            if(data && data.quantity && data.quantity > 0) {
                if (debug) { console.log(data.quantity) }

                let cartItems = document.querySelector('.cart-items');

                if (cartItems) {
                    cartItems.innerHTML = data.quantity;
                }
            }
        })
    }

};

document.addEventListener('DOMContentLoaded', function() {
    let langElement = document.getElementById('language');
    let userMenuButton = document.getElementById('user-menu-button');
    let loginButton = document.getElementById('user-menu-login-button');

    langElement.addEventListener('click', function() {
        langElement.classList.toggle('active');
    });

    userMenuButton.addEventListener('click', function() {
        userMenuButton.parentElement.classList.toggle('active');
    });

    if (loginButton) {
        loginButton.addEventListener('click', function(e) {
            e.preventDefault();

            let loginInput = document.querySelector('#user-menu-popup [name="login"]');
            let passwordInput = document.querySelector('#user-menu-popup [name="password"]');
            let alertElement = document.querySelector('#user-menu-popup .alert');

            let data = [];
            data.push('login=' + encodeURIComponent(loginInput.value));
            data.push('password=' + encodeURIComponent(passwordInput.value));

            fetch('/index.php?route=vima/account/check', {
                'method': 'POST',
                'headers': {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                'body': data.join('&')
            })
            .then(function(response) { return response.json(); })
            .then(function(response) {
                if (response.valid) {
                    document.querySelector('#user-menu-popup form').submit();
                } else {
                    loginInput.value = "";
                    passwordInput.value = "";
                    loginInput.classList.add('is-invalid');
                    passwordInput.classList.add('is-invalid');
                    alertElement.style.display = 'block';
                }
            });
        })
    }

    cart.loadInfo();
});

/*
document.addEventListener('DOMContentLoaded', function() {
    if (document.cookie.search(/doSomethingOnlyOnce=true/) < 0) {
        document.querySelector('#cookie-warning').classList.add('show');
        document.querySelector('#cookie-warning button').addEventListener('click', function() {
            document.cookie = "doSomethingOnlyOnce=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
            document.querySelector('#cookie-warning').classList.remove('show');
        });
    }
});
*/
